<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import { authComputed } from "../../../state/helpers";
import Axios from "@/config/axios";
import store from "../../../state/store";

export default {
  page: {
    title: "Dates de départ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  async beforeRouteEnter() {
    const settings = await Axios.get("/admin/app-settings").then(({ data }) => {
      return data;
    });

    localStorage.setItem("@APP_SETTINGS", JSON.stringify(settings));
  },
  data() {
    return {
      title: "Dates de départ",
      items: [
        {
          text: "Velzon",
          href: "/",
        },
        {
          text: "Dates de départ",
          active: true,
        },
      ],
      value: ["javascript"],
      date: null,
      fields: {
        airport_departure_next_date: "",
        airport_departure_desc: "",
        airport_departure_city: "",
        sea_departure_next_date: "",
        sea_departure_desc: "",
        sea_departure_city: "",
      },
    };
  },
  methods: {
    async handleSubmit() {
      store.dispatch("setGlobalLoading", true);

      await Axios.post("/admin/app-settings", this.fields).then(() => {
        alert("Modification enregistrée !");
      });
      store.dispatch("setGlobalLoading", false);
    },
  },
  computed: { ...authComputed },
  mounted() {
    let jsonData = localStorage.getItem("@APP_SETTINGS");
    jsonData = jsonData ? JSON.parse(jsonData) : {};

    this.fields.airport_departure_next_date =
      jsonData.airport_departure_next_date;
    this.fields.airport_departure_desc = jsonData.airport_departure_desc;
    this.fields.airport_departure_city = jsonData.airport_departure_city;
    this.fields.sea_departure_next_date = jsonData.sea_departure_next_date;
    this.fields.sea_departure_desc = jsonData.sea_departure_desc;
    this.fields.sea_departure_city = jsonData.sea_departure_city;
  },
  components: {
    Layout,
  },
};
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img
          src="@/assets/images/profile-bg.jpg"
          class="profile-wid-img"
          alt=""
        />
      </div>
    </div>

    <div class="row">
      <!--end col-->
      <div class="col-xxl-12">
        <div class="card mt-xxl-n5">
          <div class="card-header">
            <ul
              class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#aerienDetails"
                  role="tab"
                >
                  <i class="fas fa-home"></i>
                  Départ aérien
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#maritimeDetails"
                  role="tab"
                >
                  <i class="far fa-user"></i>
                  Départ maritime
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <div class="tab-pane active" id="aerienDetails" role="tabpanel">
                <form
                  action="javascript:void(0);"
                  @submit.prevent="handleSubmit"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstnameInput" class="form-label"
                          >Date de départ</label
                        >
                        <input
                          type="date"
                          class="form-control"
                          id="firstnameInput"
                          placeholder=""
                          v-model="fields.airport_departure_next_date"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="emailInput" class="form-label"
                          >Ville de dépôt</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="emailInput"
                          placeholder=""
                          v-model="fields.airport_departure_city"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="emailInput" class="form-label"
                          >Détails sur la fréquence</label
                        >
                        <textarea
                          rows="3"
                          class="form-control"
                          v-model="fields.airport_departure_desc"
                          placeholder=""
                        ></textarea>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="submit" class="btn btn-primary">
                          Enregistrer
                        </button>
                        <button type="button" class="btn btn-soft-success">
                          Annuler
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="maritimeDetails" role="tabpanel">
                <form
                  action="javascript:void(0);"
                  @submit.prevent="handleSubmit"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstnameInput" class="form-label"
                          >Date de départ</label
                        >
                        <input
                          type="date"
                          class="form-control"
                          id="firstnameInput"
                          placeholder=""
                          v-model="fields.sea_departure_next_date"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="emailInput" class="form-label"
                          >Ville de dépôt</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="emailInput"
                          placeholder=""
                          v-model="fields.sea_departure_city"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="emailInput" class="form-label"
                          >Détails sur la fréquence</label
                        >
                        <textarea
                          rows="3"
                          class="form-control"
                          v-model="fields.sea_departure_desc"
                          placeholder=""
                        ></textarea>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="submit" class="btn btn-primary">
                          Enregistrer
                        </button>
                        <button type="button" class="btn btn-soft-success">
                          Annuler
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
